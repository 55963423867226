import { GoogleNewsIcon } from 'components/icons/google-news'
import { preventDefault, preventDefaultThen } from 'components/link'
import * as Loading from 'components/loading'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import { isEmpty } from 'lodash'
import React from 'react'
import { Button } from 'reactstrap'
import * as Redux from 'redux'
import { SourceTypes } from 'shared_server_client/constants'
import { injectReducer } from 'store/index'
import * as GlobalConstants from '../../../constants'
import { DEFAULT_ARTICLE_DESCRIPTION_CAP } from '../constants'
import { Article } from '../utility/components'
import { isValidSourceName, postAddSource } from '../utility/utils'
import './_styles.scss'
import * as Constants from './constants'

interface AddFeedState {
  error?: string,
  existingSources: any[],
  feed: any,
  found: boolean,
  isLoading: boolean,
  name: string,
  selectedCommunity: string,
  url: string,
}

class ContentPoolAddGoogleSourceComponent extends RasaReactComponent<any, AddFeedState> {
  public static contextType = RasaContext;
  constructor(props: any) {
    super(props, 'communitySource', {
      existingSources: [],
      feed: {},
      found: true,
      isLoading: false,
      name: '',
      selectedCommunity: '',
      url: '',
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({person, activeCommunity}) => {
      this.loadRecord(activeCommunity.communityId, null)
      new Dataset()
        .loadCommunityDataset('communitySources', activeCommunity.communityId)
        .then((response) => {
          this.setState({
            existingSources: response[0] || [],
            selectedCommunity: activeCommunity.communityId,
          })
        })
    })
  }

  public render = () => {
    return <div className={`${this.context.store.getState().config.isMobile ? 'add-google-wrapper-mobile' : 'add-google-wrapper'}`}>
      <h2 className="sources-heading">Sources</h2>
      <div className="section-header-text">Add New {Constants.SOURCE_NAME} News Feed</div>
      { GlobalConstants.RASA_HELP_ADD_GOOGLE_NEWS &&
      <p className="section-detail-text">Add a {Constants.SOURCE_NAME} News url.
      <a href={GlobalConstants.RASA_HELP_ADD_GOOGLE_NEWS} target="_blank" rel="noopener"> Learn how to create a Google News Feed.</a></p>}
      {isEmpty(this.state.feed) && <div className="google sources">
        <div className="image">
          <GoogleNewsIcon svgwidth={40} svgheight={40} />
        </div>
        <div className="words">
          <h2>{Constants.SOURCE_NAME} News</h2>
          <p>Enter a {Constants.SOURCE_NAME} News URL</p>
          <div className="input-area">
            <form onSubmit={preventDefaultThen(() => this.searchSource(this.state))}>
              <input autoFocus disabled={this.state.isLoading} className="field-textbox"
                    value={this.state.url}
                    onChange={(e: any) => this.setState({ found: true, url: e.target.value })}/>
              <Button>Next</Button>
            </form>
          </div>
          {!this.state.found && <span className="warning">
            {this.state.error || 'We could not locate a feed for this URL'}
            </span>}
        </div>
      </div>}
      {!isEmpty(this.state.feed) && <div className="">
        <div className="google confirm sources">
          <div className="image">
            <GoogleNewsIcon svgwidth={40} svgheight={40} />
          </div>
          <div className="words">
            <h2>{Constants.SOURCE_NAME} RSS feed</h2>
            <p>
              <strong>We found your source, <span className="wordBreak">{this.state.feed.url}</span>.</strong>
            </p>
            <p>Specify how you would like this source to be named in your newsletter and hit confirm.</p>
            {!isValidSourceName(this.state.existingSources, this.state.name) &&
            <div className="invalid-source-name-container">You already have a source with this name</div>}
            <div className="input-area">
              <form onSubmit={preventDefault()}>
                <input autoFocus className="field-textbox"
                  value={this.state.name}
                  onChange={(e: any) => this.setState({ name: e.target.value })}/>
                <Button onClick={() => this.addSource({
                  community_id: this.context.user.activeCommunity.communityId,
                  identifier: this.state.feed.url,
                  name: this.state.name,
                  type: SourceTypes.googleNews})}
                  disabled={this.state.isLoading ||
                    !isValidSourceName(this.state.existingSources, this.state.name)}>Confirm</Button>
                <Button className="change" onClick={() => this.setState({feed: {}})}>Change</Button>
              </form>
            </div>
          </div>
        </div>
      </div>}
      {this.state.isLoading && <Loading.Loading size={Loading.SIZES.LARGE}/>}
      {!isEmpty(this.state.feed) && this.state.feed.items.length > 0 &&
        <div className={`${this.context.store.getState().config.isMobile ? 'recent-articles-mobile' : 'recent-articles'}`}>
          <h4>Recent Articles</h4>
        {this.state.feed.items.slice(0, 5).map((feed, i) =>
          <Article key={i} article={feed} descriptionCap={DEFAULT_ARTICLE_DESCRIPTION_CAP}/>)}
      </div>}
    </div>
  }

  public searchSource(state) {
    this.setState({isLoading: true}, () =>
      AjaxWrapper
        .ajax(`${AjaxWrapper.getServerUrl()}/google/${state.selectedCommunity}/find`, HttpMethod.POST, {
          application: 'dashboard',
          feedUrl: state.url,
          identifier: state.selectedCommunity,
        })
        .then((feed) => this.setState({
          feed,
          found: true,
          isLoading: false,
          name: feed.title,
        }))
        .catch((err) => {
          this.setState({
            error: err.response.message,
            feed: {},
            found: false,
            isLoading: false,
            name: '',
          })
        }))
  }

  public addSource(source) {
    this.props.propertiesChanged(source)
    this.saveRecord(null).then(() => {
      postAddSource(this.context, 'google', source.identifier, this.props.push)
    })
  }
}

export const ContentPoolAddGoogleSource = GenericRedux.createConnect(
  ContentPoolAddGoogleSourceComponent,
  Constants.REDUX_STORE_HOME,
)
injectReducer(
  Constants.REDUX_STORE_HOME,
  Redux.combineReducers({
    data: GenericRedux.createGenericReducer(
      Constants.REDUX_STORE_HOME,
      {url: ''},
    ),
  }),
)
